<template>
  <div>
    <div id="site-wrapper" v-if="user" class="site-wrapper panel panel-ad-campaigns-start-new">
      <!-- #header start -->
      <NavBar />
      <!-- #header end -->
      <!-- #wrapper-content start -->
      <div id="wrapper-content" class="wrapper-content pt-0 pb-0">
        <div class="page-wrapper d-flex flex-wrap flex-xl-nowrap">
          <LeftSideBar />
          <div class="page-container">
            <div class="container-fluid">
              <div class="page-content-wrapper d-flex flex-column">

                <div id="section-accordion">

                  <div class="row">
                    <div class="col-md-12">
                      <div class="accordion mb-6" id="accordionExample">
                        <div class="card mb-4">
                          <a href="#collapseOne" class="card-header text-decoration-none collapsed"
                            data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne" id="headingOne">
                            <span class="card-icon"><i class="fas fa-plus"></i></span>
                            <span class="card-title mb-0">
                              New Business
                            </span>
                            <span class="dynamic-icon"></span>
                          </a>
                          <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                            data-parent="#accordionExample" style="">
                            <div class="card-body">
                                 <CreateMyBusiness />

                            </div>
                          </div>
                        </div>
                        <div class="card mb-4">
                          <a href="#collapseTwo" :disabled="true ? '' : true" class="card-header text-decoration-none collapsed" id="headingTwo"
                            data-toggle="collapse" aria-expanded="true" aria-controls="collapseTwo">
                            <span class="card-icon"><i class="fas fa-location"></i></span>
                            <span class="card-title mb-0">
                              Business Address
                            </span>
                            <span class="dynamic-icon"></span>
                          </a>
                          
                        </div>
                        <div class="card mb-4">
                          <a href="#collapseThree" :disabled="true ? '' : true" class="card-header text-decoration-none collapsed" id="headingThree"
                            data-toggle="collapse" aria-expanded="false" aria-controls="collapseThree">
                            <span class="card-icon"><i class="far fa-images"></i></span>
                            <span class="card-title mb-0">
                              Business Images
                            </span>
                            <span class="dynamic-icon"></span>
                          </a>
                          
                        </div>
                        
                          <div class="card mb-4">
                          <a href="#collapseFive" :disabled="true ? '' : true" class="card-header text-decoration-none collapsed" id="headingFive"
                            data-toggle="collapse" aria-expanded="false" aria-controls="collapseFive">
                            <span class="card-icon"><i class="far fa-gift"></i></span>
                            <span class="card-title mb-0">
                              Subscription
                            </span>
                            <span class="dynamic-icon"></span>
                          </a>
                          
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
               
                <div class="lh-1 mt-5">
                  <FooterBar />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- #wrapper-content end -->
    </div>


    <div v-else>
      <LoadingScreen />
    </div>
    
  </div>
</template>
<script>
  import {
    mapGetters,
    mapActions
  } from "vuex";
  import NavBar from "../Layouts/NavBar";
  import LeftSideBar from "../Layouts/LeftSideBar";
  import FooterBar from "../Layouts/FooterBar";
  import LoadingScreen from "../Layouts/LoadingScreen";
  import CreateMyBusiness from "../MyBusiness/CreateMyBusiness";
  //
  export default {
    name: "CreateBusinessStepper",

    components: {
      NavBar,
      LeftSideBar,
      FooterBar,
      CreateMyBusiness,
      LoadingScreen
    },
    computed: {
      ...mapGetters("auth", ["user"])
    },

    methods: {
      ...mapActions("auth")
    }
  };
</script>